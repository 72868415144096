@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #f1356d;
}
.content {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
}

.fbt-title{
  font-size: 22px;
  font-family: 'Courier New', monospace;
  text-align: center;
  font-weight: bold;
}
.fbt-title-sub{
  color: red;
}
.fbt-tag-line{
  font-family: 'Brush Script MT', cursive;
  color: gray;
  text-align: right;
}

.hr-line{
  height: 2px;
  border-width: 0;
  color: gray;
  background-color: gray;
}
.center-align{
  text-align: center;
}
.center-align-light{
  text-align: center;
  color: #737373;
}
.mission{
  font-family: 'Brush Script MT', cursive;
  color:gray;
  text-align: center;
}
.c-gray{
   color: #737373;
}
.c-blue{
   color:#335BFF;
}
.bullet{
  list-style-type: circle;
}
.bigFont{
  font-size:35px;
}
.c-white{
  color:#FFFFFF;
}
.c-ivory{
  color:#FFFFF0;
}
