.containerBox {
  position: relative;
  display: inline-block;
}

.text-box {
  position: absolute;
  height: 30%;
  text-align: center;
  width: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 30px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: 120px;
  margin: auto;
  padding: auto;
}

.dataNumber {
  margin-top: auto;
}
