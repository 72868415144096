.flex-container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dataResult {
    margin-top: 5px;
    width: 300px;
    height: 150px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
}

dataResult::-webkit-scrollbar {
    display: none;
}

.dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
}

.dataItem p {
    margin-left: 10px;

}

a {
    text-decoration: none !important;
}

a:hover {
    background-color: lightgray;
}

#clearBtn {
    cursor: pointer;
}

.searchInputs {

    display: flex;
}

.search input {
    background-color: white;
    border: 1;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    height: 60px;
    width: 300px;

}

.searchIcon {
    height: 60px;
    width: 50px;
    background-color: white;
    display: grid;
    place-items: center;

}

input:focus {
    outline: none;
}

.searchIcon svg {
    font-size: 35px;

}